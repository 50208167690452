
/* Make clicks pass-through */
#loading-bar,
#loading-bar-spinner {
  pointer-events: none;
  -webkit-pointer-events: none;
  -webkit-transition: 350ms linear all;
  -moz-transition: 350ms linear all;
  -o-transition: 350ms linear all;
  transition: 350ms linear all;
}

#loading-bar.ng-enter,
#loading-bar.ng-leave.ng-leave-active,
#loading-bar-spinner.ng-enter,
#loading-bar-spinner.ng-leave.ng-leave-active {
  opacity: 0;
}

#loading-bar.ng-enter.ng-enter-active,
#loading-bar.ng-leave,
#loading-bar-spinner.ng-enter.ng-enter-active,
#loading-bar-spinner.ng-leave {
  opacity: 1;
}

#loading-bar .bar {
  -webkit-transition: width 350ms;
  -moz-transition: width 350ms;
  -o-transition: width 350ms;
  transition: width 350ms;

  background: rgba(0,0,0,0.1);
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

/* Fancy blur effect */
#loading-bar .peg {
  position: absolute;
  width: 70px;
  right: 0;
  top: 0;
  height: 2px;
  opacity: .45;
  -moz-box-shadow: #fff 1px 0 6px 1px;
  -ms-box-shadow: #fff 1px 0 6px 1px;
  -webkit-box-shadow: #fff 1px 0 6px 1px;
  box-shadow: #fff 1px 0 6px 1px;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
    z-index: 99999;
}



width: 56px;
height: 56px;
text-align: center;
padding: 14px;
border-radius: 50%;
position: absolute;
top: 105px;
left: 50%;
margin-left: -28px;
background: #fff;
.z-depth(1);
    
    & > div {
        
    }
    
    .clearfix();

#loading-bar-spinner {
    width: 56px;
    height: 56px;
    text-align: center;
    padding: 14px;
    border-radius: 50%;
    position: absolute;
    top: 105px;
    left: 50%;
    margin-left: -28px;
    background: #fff;
    .z-depth(1);
}

#loading-bar-spinner .spinner-icon {
  display: inline-block;
        width: 28px;
        height: 28px;
        margin: 0;
        float: left;
        background: transparent !important;
        border: 2px solid @m-cyan;
        border-bottom-color: transparent !important;
        border-radius: 100%;
        .animation(ball-clip-rotate .75s linear infinite);
}

@-webkit-keyframes loading-bar-spinner {
  0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes loading-bar-spinner {
  0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes loading-bar-spinner {
  0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes loading-bar-spinner {
  0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}
